<script lang="ts">
  import Footer from '$lib/components/footer/Footer.svelte';
  import Navbar from '$lib/components/navbar/Navbar.svelte';
  import { dev } from '$app/environment';
  import '../app.scss';
  import type { LayoutLoadData } from './+layout';

  export let data: LayoutLoadData;
</script>

<svelte:head>
  {#if !dev}
    <!-- Matomo -->
    <script type="text/javascript">
      var _paq = (window._paq = window._paq || []);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['disableCookies']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function () {
        var u = '//matomo-uzun.paas.uzun-apps.de/';
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '1']);
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = u + 'matomo.js';
        s.parentNode.insertBefore(g, s);
      })();
    </script>
    <!-- End Matomo Code -->
  {/if}
</svelte:head>

<div class="app">
  <Navbar navbarLinks={data.navbarLinks} />

  <main class="min-h-screen">
    <slot />
  </main>

  <footer>
    <Footer footerLinks={data.footerLinks} />
  </footer>
</div>
